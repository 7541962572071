<template>
  <Teleport to="body">
    <div
      v-if="cmsBlock"
      class="modal contdown__root"
    >
      <div class="modal__body ">
        <button
          class="countdown-timer__close"
          @click="onClosePopup"
        >
          <img
            src="~/assets/img/svg/countdown-popup/close-primary.svg"
            alt="close icon"
          >
        </button>
        <div class="countdown-timer__content">
          <div class="countdown-timer__left">
            <div
              v-if="title"
              class="countdown-timer__title"
              v-html="title"
            />
            <div
              v-if="message"
              class="countdown-timer__message"
              v-html="message"
            />

            <!-- Форма -->
            <div
              v-if="formVisible"
              class="countdown-timer__form"
            >
              <!-- Имя -->
              <div
                v-if="formNameVisible"
                class="--element"
              >
                <div class="--element-title">{{ formNameTitle }}</div>
                <input
                  v-model="state.name"
                  :placeholder="formNamePlaceholder"
                  type="text"
                  class="--element-input"
                />
                <div
                  v-for="(error, index) in $v.name.$errors"
                  :key="index"
                  class="--element-error"
                >
                  {{ error.$message }}
                </div>
              </div>

              <!-- Телефон -->
              <div
                v-if="formPhoneVisible"
                class="--element"
              >
                <div class="--element-title">
                  {{ formPhoneTitle }}
                  <div v-if="formPhoneTitle">*</div>
                </div>
                <input
                  v-model="state.phone"
                  v-maska
                  :placeholder="formPhonePlaceholder"
                  type="text"
                  class="--element-input"
                  data-maska="+7(###) ###-##-##"
                />
                <div
                  v-for="(error, index) in $v.phone.$errors"
                  :key="index"
                  class="--element-title"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <!--            Обратный отсчет-->
            <div
              v-if="Boolean(countdownVisible && difference > 0)"
              class="countdown-timer__timer"
            >
              <div class="--items">
                <div
                  v-for="(item, index) in countdownItems"
                  :key="`countdownItems-${index}`"
                >
                  <div
                    class="--count"
                    :class="{'--dots': item.dots}"
                  >
                    {{ item.value }}
                  </div>
                  <div class="--name">{{ item.text }}</div>
                </div>
              </div>
            </div>

            <!-- Органы управления -->
            <div
              v-if="formVisible"
              class="countdown-timer__submit"
            >
              <button
                class="btn primary --shadow"
                :disabled="!isAccept || isSendingForm"
                @click="submit"
              >
                {{ textButton }}
              </button>

              <span class="label-condition">
                  Нажимая на кнопку, Вы соглашаетесь с
                  <NuxtLink
                    target="_blank"
                    href="/privacy"
                  >
                    политикой конфиденциальности
                  </NuxtLink>
                </span>
            </div>
          </div>
          <div class="countdown-timer__right">
            <div class="countdown-timer__image">
              <nuxt-img
                :fit="$viewport.isLessThan('md')? 'crop' : 'contain'"
                :width="$viewport.isLessThan('md')? 778 : 450"
                :height="$viewport.isLessThan('md')? 360 : 540"
                placeholder
                :src="String(imageId)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal__overlay"
        @click="onClosePopup"
      />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { helpers, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import type { CountdownContentType } from '~/types/CountdownBlock';
import { reactive } from '#imports';
import type { FormSendType } from '~/types/Commons';
import parseUtmSessionStorage from "~/utils/forms/parseUtmSessionStorage";
import {togglePageScroll} from "~/utils/scroll/togglePageScroll";

const { onCloseCountdownPopup } = useCountdownPopup();
const appConfigStore = useAppConfigStore();
const { vars } = storeToRefs(appConfigStore);
const { $api, $toast } = useNuxtApp();
const $viewport = useViewport();

const state = reactive<FormSendType>({
  name: '',
  phone: '',
  type: 'Обратный звонок'
});

const rules = {
  name: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  },
  phone: {
    required: helpers.withMessage('Обязательно к заполнению', required),
    minLength: helpers.withMessage('Неверный формат', minLength(17))
  }
};
const $v = useVuelidate(rules, state);

const difference = ref<number>(0);
const cmsBlock = ref<CountdownContentType | null>(null);
const setTimeoutCountdown = ref<ReturnType<typeof setTimeout>>();
const isAccept = ref(true);
const isSendingForm = ref(false);

const getContent = async () => {
  const pageId = vars?.value?.['ID CMS страницы обратного отсчета'];
  if (!pageId) {
    return;
  }

  const content = await $api.agent.get(`/cms/page/${pageId}`)
    .then((res) => res._data)
    .catch((e) => {
      console.log('get-content error:', errorParserServerResponse(e?.response ?? e));

      return null;
    });

  const cmsBlockRes = (content?.placeholders?.content || []).find((t: any) => t.block_name === 'CountdownTimerPopupBlock');

  cmsBlock.value = cmsBlockRes?.values as CountdownContentType;
};

const onClosePopup = () => {
  state.name = '';
  state.phone = '';
  onCloseCountdownPopup();
};

const startCountdown = () => {
  const current = new Date().getTime();
  const endDate = (cmsBlock?.value?.countdown || 1) * 1000;
  difference.value = endDate - current;

  setTimeoutCountdown.value = setTimeout(() => {
    startCountdown();
  }, 1000);
};

const countdownItems = computed(() => {
  const days = Math.floor(difference.value / (1000 * 3600 * 24));
  const hours = Math.floor((difference.value / (1000 * 3600)) % 24);
  const minutes = Math.floor((difference.value / 1000 / 60) % 60);
  const seconds = Math.floor((difference.value / 1000) % 60);

  return [
    { value: days, text: 'Дни' },
    { value: hours, text: 'Часы' },
    { value: minutes, text: 'Мин.', dots: true },
    { value: seconds, text: 'Сек.', dots: true }
  ];
});

const title = computed(() => cmsBlock?.value?.title || '');
const message = computed(() => cmsBlock?.value?.message || '');
const imageId = computed(() => {
  return ($viewport.isLessThan('md') && cmsBlock?.value?.mobile_image) ? cmsBlock?.value?.mobile_image : cmsBlock?.value?.image;
});
const formVisible = computed(() => !cmsBlock?.value?.form_hidden);
const countdownVisible = computed(() => !cmsBlock?.value?.countdown_hidden);
const formNameTitle = computed(() => cmsBlock?.value?.form_name_title);
const formNamePlaceholder = computed(() => cmsBlock?.value?.form_name_placeholder);
const formNameVisible = computed(() => !cmsBlock?.value?.form_name_hidden);
const formPhoneTitle = computed(() => cmsBlock?.value?.form_phone_title);
const formPhonePlaceholder = computed(() => cmsBlock?.value?.form_phone_placeholder);
const formPhoneVisible = computed(() => !cmsBlock?.value?.form_phone_hidden);
const textButton = computed(() => cmsBlock?.value?.text_button);

const submit = async () => {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }

  isSendingForm.value = true;

  const body: any = {
    type: cmsBlock?.value?.form_type || 'Обратный звонок',
    name: state.name,
    phone: (state?.phone || '').replace(/[^\d]/g, ''),
    url: window.location.href,
    additional_data: parseUtmSessionStorage(),
    source: useCookie('sbjs_current').value || '',
  };

  const response = await $api.agent.post('form/send', {
    body: { ...body }
  })
    .then((res) => res._data).catch((err) => ({
      error: errorParserServerResponse(err.response)
    }));

  if (response.error) {
    isSendingForm.value = false;
    $toast.error(response.error);
    return;
  }

  isSendingForm.value = false;
  $toast.success('Заявка успешно отправлена');

  onClosePopup();
};

onMounted(async () => {
  await getContent();

  if (cmsBlock.value) {
    togglePageScroll(false)
    startCountdown();
  }
});

onBeforeUnmount(() => {
  clearTimeout(setTimeoutCountdown.value);
  togglePageScroll(true)
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.contdown__root {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;

  & .modal__overlay {
    background-color: rgb(60 29 109 / 80%);
    backdrop-filter: blur(8px);
    opacity: 1;
  }

  .modal__body {
    max-width: 820px;
    padding: 0;
    background: var(--primary-10);
    border-radius: 20px;
    overflow: initial;
    position: relative;
    margin: auto;
  }

  .modal__close {
    width: 18px;
    height: 18px;
    top: 32px;

    & > img {
      filter: invert(91%) sepia(59%) saturate(285%) hue-rotate(183deg) brightness(96%) contrast(90%);
    }
  }
}

.countdown-timer__container {
  width: 100%;
  max-width: 820px;
  margin: auto;
  position: relative;

}

.countdown-timer__content {
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid rgb(60 29 109 / 15%);
  background: var(--primary-10);
  box-shadow: 0 0 0 0 rgb(0 0 0 / 4%),
  0 2px 4px 0 rgb(0 0 0 / 4%),
  0 7px 7px 0 rgb(0 0 0 / 4%),
  0 16px 9px 0 rgb(0 0 0 / 3%),
  0 28px 11px 0 rgb(0 0 0 / 1%),
  0 44px 12px 0 rgb(0 0 0 / 0%);
}

.countdown-timer__left {
  width: 100%;
  max-width: 365px;
  padding: 36px 40px 45px 45px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.countdown-timer__right {
  display: flex;
  flex: 1;
  position: relative;
  z-index: 0;
  filter: drop-shadow(0 0 30px rgb(60 29 109 / 50%));
}

.countdown-timer__title {
  margin-bottom: 10px;
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
}

.countdown-timer__message {
  color: var(--primary);
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.32px;

  * {
    all: revert;
    margin: 0;
  }
}

.countdown-timer__form {
  display: flex;
  flex-direction: column;
  margin-top: 26px;

  .--element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .--element-title {
    margin-bottom: 8px;
    position: relative;
    color: var(--primary);
    font-family: var(--font-family-secondary);
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.36px;

    div {
      position: absolute;
      top: -5px;
      right: -15px;
      font-size: 25px;
      line-height: 100%;
      color: var(--secondary);
    }
  }

  .--element-input {
    width: 100%;
    border-radius: 10px;
    background: var(--white);
    border: 1px solid rgb(60 29 109 / 10%);
    outline: none;
    box-shadow: none;
    padding: 14px 17px;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary);
    letter-spacing: -0.56px;

    &:focus {
      border-color: var(--primary);
    }

    &::placeholder {
      color: #B1A5C5;
    }
  }

  .--element-error {
    font-family: var(--font-family-secondary);
    margin-top: 3px;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary);
    letter-spacing: .02em;
    text-align: right;
  }

  & > * {
    margin-top: 23px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.countdown-timer__timer {
  margin-top: 32px;

  .--title {
    margin-bottom: 11px;
    text-align: center;
    color: var(--primary);
    font-family: var(--font-family-secondary);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.36px;
  }

  .--items {
    display: flex;
    margin-left: -8px;

    & > * {
      width: calc(100% / 4 - 8px);
      margin-left: 8px;
    }
  }

  .--count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 73px;
    border-radius: 10px;
    background: linear-gradient(180deg, #F1F0F3 0%, #FFF 100%);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 4%),
    0 2px 4px 0 rgb(0 0 0 / 4%),
    0 7px 7px 0 rgb(0 0 0 / 4%),
    0 16px 9px 0 rgb(0 0 0 / 3%),
    0 28px 11px 0 rgb(0 0 0 / 1%),
    0 44px 12px 0 rgb(0 0 0 / 0%);
    color: var(--primary);
    text-align: center;
    font-family: var(--font-family-secondary);
    font-size: 36px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.72px;
    text-shadow: 0 2px 4px rgb(60 29 109 / 15%);
  }

  .--count.--dots {
    position: relative;

    &::before {
      content: ":";
      color: var(--primary-40);
      text-shadow: 0 2px 4px rgb(60 29 109 / 15%);
      font-family: var(--font-family-secondary);
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      position: absolute;
      width: 10px;
      text-align: center;
      right: calc(100% - 1px);
    }
  }

  .--name {
    margin-top: 9px;
    color: var(--primary-60);
    font-family: var(--font-family-secondary);
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.36px;
    text-align: center;
  }
}

.countdown-timer__submit {
  margin-top: 30px;

  .btn {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    cursor: pointer;

    input {
      display: flex;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      border-radius: 2px;
      margin-right: 8px;
      background-color: white;
      border-color: white;

      &:checked, &[checked="true"] {
        --chkbg: 0 0% 100%;
        --chkfg: 263 30% 42%;

        background-color: white;
        background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
      }
    }

    span {
      max-width: 190px;
      flex: 1;
      color: var(--primary-40);
      font-family: var(--font-family-secondary);
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.24px;

      a:hover {
        color: var(--secondary);
      }
    }
  }
}

.countdown-timer__image {
  flex: 1;
  position: absolute;
  inset: -86px -130px -86px 0;
  clip-path: ellipse(50% 50% at 50% 50%);

  img {
    position: absolute;
    top: 86px;
    left: 0;
    width: calc(100% - 130px);
    height: calc(100% - 172px);
    object-fit: cover;

  }
}

.countdown-timer__close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgb(60 29 109 / 15%);
  background: #F5F4F8;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 4%),
  0 2px 4px 0 rgb(0 0 0 / 4%),
  0 7px 7px 0 rgb(0 0 0 / 4%),
  0 16px 9px 0 rgb(0 0 0 / 3%),
  0 28px 11px 0 rgb(0 0 0 / 1%),
  0 44px 12px 0 rgb(0 0 0 / 0%);
  cursor: pointer;

  img {
    transition: all 0.3s;
  }

  &:hover img {
    transform: rotate(180deg);
  }
}

@include media('md') {
  .countdown-timer__container {
    max-width: 700px;
  }

  .countdown-timer__left {
    padding: 20px;
    max-width: 320px;
  }
}

@include media('md') {
	.contdown__root {
		padding: 60px 32px 16px;
	}

  .countdown-timer__content {
    flex-direction: column-reverse;
    padding: 8px;
    box-sizing: border-box;
  }

  .countdown-timer__left {
    max-width: initial;
    padding: 26px 12px 12px;
  }

  .countdown-timer__right {
    max-height: 360px;
    filter: initial;

    &::after {
      content: "";
      float: left;
      padding-top: 93%;
    }
  }

  .countdown-timer__image {
    inset: 0;
    width: 100%;
    height: 100%;
    clip-path: initial;
    border-radius: 16px;
    overflow: hidden;

    img {
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .countdown-timer__title {
    //max-width: 200px;
    margin: 0 auto 12px;
    font-size: 24px;
    text-align: center;
  }

  .countdown-timer__message {
    font-size: 14px;
    text-align: center;
    max-width: 230px;
    margin: 0 auto;
  }

  .countdown-timer__form {
    margin-top: 31px;

    & > * {
      margin-top: 20px;
    }
  }

  .countdown-timer__close {
    left: initial;
    right: 0;
    top: initial;
    bottom: calc(100% + 10px);
  }

  .countdown-timer__timer {
    margin-top: 21px;

    .--count.--dots {
      &::before {
        content: none;
      }
    }
  }
}

@include media('sm') {
	.contdown__root {
		padding: 60px 14px 16px;
	}

  .countdown-timer__left {
    padding: 20px 12px 12px;
  }

  .countdown-timer__message {
    max-width: 265px;
  }

  .countdown-timer__form {
    margin-top: 12px;

		& > * {
			margin-top: 4px;
		}
	}

	.countdown-timer__submit {
		margin-top: 16px;
	}

	.countdown-timer__right {
		max-height: 202px;
	}
}
</style>
